.loading-gif {
    width: 40px;
}

.loading-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}