.previews {
    display: flex;
    justify-content: center;
}

.modal {
    width: 100% !important;
    margin-left: 120px;
}

.modal-body {
    display: flex;
    justify-content: center;
}

.invisible {
    display: none;
}

.menuItem {
    padding-top: 2em;
}